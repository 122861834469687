<template>
  <div>
    <b-row class="match-height">
      <b-col lg="5">
        <b-overlay
          :show="ShowRound"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-card>
            <validation-observer ref="simpleRound">
              <b-row>
                <b-col lg="6">
                  <h3>เปิด-ปิด รอบแทง</h3>
                </b-col>

              </b-row>

              <b-form-group
                label="หวย"
                label-for="customDelimiter"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <v-select
                    v-model="LottoHead"
                    :disabled="EditID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="LottoList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="ele => ele.value"
                    @input="LottoSubHead = null, FindSublist(LottoHead), ClearItems(), CheckResult()"
                  />
                  <small class="text-danger">{{ errors[0] ? 'กรุณาเลือกประเภทหวย' : '' }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="LottoSubList"
                label="ประเภทหวย"
                label-for="customDelimiter"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <v-select
                    v-model="LottoSubHead"
                    :disabled="EditID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="LottoSubList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="ele => ele.value"
                    @input="ClearItems(), CheckResult()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- StatusResult -->
              <div v-if="!StatusResult">
                <div class="alert alert-primary p-1">
                  ยังไม่ได้ออกรางวัลไม่สามารถเปิดรอบใหม่ได้ค่ะ
                </div>
              </div>
              <div v-else-if="((LottoHead && !LottoSubList) || LottoSubHead) && !StatusRate">
                <div class="alert alert-danger p-1">
                  ยังไม่มีข้อมูลอัตราการจ่ายเงินไม่สามารถเปิดรอบใหม่ได้ค่ะ
                </div>
              </div>

              <div v-if="((LottoHead && !LottoSubList) || LottoSubHead) && StatusResult && StatusRate">
                <b-row>
                  <b-col lg="6">
                    <b-form-group
                      label="งวดประจำวันที่"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="round"
                        rules="required"
                      >
                        <!-- <b-form-datepicker
                          id="example-datepicker"
                          v-model="CloseDate"
                          placehoder="00/00/0000"
                          :state="errors.length > 0 ? false:null"
                        /> -->
                        <date-picker
                          v-model="CloseDate"
                          value-type="format"
                          format="YYYY-MM-DD"
                          :state="errors.length > 0 ? false : null"
                        />
                        <br>
                        <small class="text-danger">{{ errors[0] ? 'กรุณาเลือกงวดประจำวันที่' : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group
                      label="เวลาปิดรอบแทง"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="time"
                        rules="required"
                      >

                        <date-picker
                          v-model="StopBetTime"
                          type="time"
                          value-type="format"
                          format="HH:mm"
                          :state="errors.length > 0 ? false : null"
                        />
                        <br>
                        <!-- <b-form-timepicker
                          id="datepicker-invalid"
                          v-model="StopBetTime"
                          locale="th"
                          :state="errors.length > 0 ? false:null"
                        /> -->
                        <small class="text-danger">{{ errors[0] ? 'กรุณาเลือกเวลาปิดรอบแทง' : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        v-if="!EditID"
                        variant="primary"
                        @click="validatRound"
                      >
                        ส่งข้อมูล
                      </b-button>
                      <b-button
                        v-if="EditID"
                        variant="warning"
                        class="mr-2"
                        @click="validatRound"
                      >
                        แก้ไขข้อมูล
                      </b-button>
                      <b-button
                        v-if="EditID"
                        variant="secondary"
                        @click="LottoHead = null, LottoSubHead = null, ClearItems()"
                      >
                        ยกเลิก
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col lg="7">
        <b-card no-body>
          <b-card-body>
            <div class="p-1">
              <h3>รอบล่าสุด</h3>
            </div>
          </b-card-body>
          <b-table
            striped
            hover
            responsive
            class="position-relative items-center"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>
            <template #cell(StatusResult)="data">
              <b-badge
                :variant="
                  data.item.StatusResult === true
                    ? 'success'
                    : 'warning'
                "
                class="badge-glow"
              >
                {{
                  data.item.StatusResult === true
                    ? 'ออกรางวัลแล้ว'
                    : 'ยังไม่ออกรางวัล'
                }}
              </b-badge>
            </template>

            <template #cell(LottoSubHead)="data">
              {{ data.item.LottoSubHead ? NameLottoSubHead(data.item.LottoHead, data.item.LottoSubHead) : '-' }}
            </template>
            <template #cell(LimitU3)="data">
              <div
                v-if="data.item.LimitU3 && data.item.LimitU3.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitU3)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-danger"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitD3)="data">
              <div
                v-if="data.item.LimitD3 && data.item.LimitD3.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitD3)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-danger"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitT3)="data">
              <div
                v-if="data.item.LimitT3 && data.item.LimitT3.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitT3)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-danger"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitU2)="data">
              <div
                v-if="data.item.LimitU2 && data.item.LimitU2.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitU2)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-warning"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitD2)="data">
              <div
                v-if="data.item.LimitD2 && data.item.LimitD2.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitD2)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-warning"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitT2)="data">
              <div
                v-if="data.item.LimitT2 && data.item.LimitT2.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitT2)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-warning"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitU1)="data">
              <div
                v-if="data.item.LimitU1 && data.item.LimitU1.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitU1)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-success"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(LimitD1)="data">
              <div
                v-if="data.item.LimitD1 && data.item.LimitD1.length > 0"
                class="text-nowrap"
                @click="ModalLimit(data.field.label, data.item.LimitD1)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-success"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
            <template #cell(action)="data">
              <div
                v-if="data.item.StatusResult === false"
                class="text-nowrap"
                @click="LottoHead = null, LottoSubHead = null, EditFu(data.item)"
              >
                <b-avatar
                  rounded="sm"
                  variant="light-info"
                >
                  <feather-icon
                    :id="`invoice-row-${data.item._id}-preview-icon`"
                    icon="EditIcon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-1 ml-1"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>

        </b-card>
      </b-col>

      <b-col
        v-if="((LottoHead && !LottoSubList) || LottoSubHead) && StatusResult && StatusRate"
        lg="12"
      >

        <!-- <b-col> -->
        <b-card no-body>
          <b-tabs
            justified
            pills
            class="p-1"
          >
            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
              title="3 ตัวบน"
              active
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>

                    <validation-observer ref="simpleRules3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % 3 ตัวบน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขจ่ายตาม % 3 ตัวบน"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotU3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % 3 ตัวบน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateU3"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushU3"
                              >
                                เพิ่มเลขจ่ายตาม % 3 ตัวบน
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateU3 = RateDefault.U3 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>

                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % 3 ตัวบน</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageU3"
                      :fields="FieldsLimit"
                      :items="ItemsU3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageU3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsU3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageU3"
                          :total-rows="ItemsU3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseU3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 3 ตัวบน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขปิดรับ 3 ตัวบน"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotU3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 3 ตัวบน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushU3"
                              >
                                เพิ่มเลขปิดรับ 3 ตัวบน
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 3 ตัวบน</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageU3"
                      :fields="FieldsCloseBet"
                      :items="CloseBetU3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageU3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetU3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageU3"
                          :total-rows="CloseBetU3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
              title="3 ตัวหน้า"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesD3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % 3 ตัวหน้า"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขจ่ายตาม % 3 ตัวหน้า"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotD3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % 3 ตัวหน้า"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateD3"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushD3"
                              >
                                เพิ่มเลขจ่ายตาม % 3 ตัวหน้า
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateD3 = RateDefault.D3 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>
                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % 3 ตัวหน้า</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageD3"
                      :fields="FieldsLimit"
                      :items="ItemsD3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageD3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsD3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageD3"
                          :total-rows="ItemsD3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseD3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 3 ตัวหน้า"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขปิดรับ 3 ตัวหน้า"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotD3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 3 ตัวหน้า"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushD3"
                              >
                                เพิ่มเลขปิดรับ 3 ตัวหน้า
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 3 ตัวหน้า</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageD3"
                      :fields="FieldsCloseBet"
                      :items="CloseBetD3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageD3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetD3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageD3"
                          :total-rows="CloseBetD3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
              title="3 ตัวท้าย"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesB3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % 3 ตัวท้าย"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขจ่ายตาม % 3 ตัวท้าย"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotB3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % 3 ตัวท้าย"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateB3"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushB3"
                              >
                                เพิ่มเลขจ่ายตาม % 3 ตัวท้าย
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateB3 = RateDefault.B3 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>
                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % 3 ตัวท้าย</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageB3"
                      :fields="FieldsLimit"
                      :items="ItemsB3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageB3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsB3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageB3"
                          :total-rows="ItemsB3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseB3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 3 ตัวท้าย"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขปิดรับ 3 ตัวท้าย"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotB3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 3 ตัวท้าย"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushB3"
                              >
                                เพิ่มเลขปิดรับ 3 ตัวท้าย
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 3 ตัวท้าย</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageB3"
                      :fields="FieldsCloseBet"
                      :items="CloseBetB3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageB3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetB3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageB3"
                          :total-rows="CloseBetB3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
              title="3 ตัวโต๊ด"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesT3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % 3 ตัวโต๊ด"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขจ่ายตาม % 3 ตัวโต๊ด"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotT3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % 3 ตัวโต๊ด"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateT3"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushT3"
                              >
                                เพิ่มเลขจ่ายตาม % 3 ตัวโต๊ด
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateT3 = RateDefault.T3 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>

                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % 3 ตัวโต๊ด</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageT3"
                      :fields="FieldsLimit"
                      :items="ItemsT3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageT3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsT3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageT3"
                          :total-rows="ItemsT3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseT3">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 3 ตัวโต๊ด"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="เลขปิดรับ 3 ตัวโต๊ด"
                              rules="required|min:3"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotT3"
                                type="number"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 3 ตัวโต๊ด"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushT3"
                              >
                                เพิ่มเลขปิดรับ 3 ตัวโต๊ด
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 3 ตัวโต๊ด</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageT3"
                      :fields="FieldsCloseBet"
                      :items="CloseBetT3"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageT3 - 1) + (data.index + 1) }}
                      </template>

                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetT3.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageT3"
                          :total-rows="CloseBetT3.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
              title="2 ตัวบน"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="simpleRules2">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % 2 ตัวบน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:2"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotU2"
                                type="number"
                                maxlength="2"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % 2 ตัวบน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateU2"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushU2"
                              >
                                เพิ่มเลขจ่ายตาม % 2 ตัวบน
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateU2 = RateDefault.U2 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>

                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % 2 ตัวบน</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageU2"
                      :fields="FieldsLimit"
                      :items="ItemsU2"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageU2 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsU2.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageU2"
                          :total-rows="ItemsU2.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseU2">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 2 ตัวบน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:2"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotU2"
                                type="number"
                                maxlength="2"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 2 ตัวบน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushU2"
                              >
                                เพิ่มเลขปิดรับ 2 ตัวบน
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 2 ตัวบน</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageU2"
                      :fields="FieldsCloseBet"
                      :items="CloseBetU2"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageU2 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetU2.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageU2"
                          :total-rows="CloseBetU2.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
              title="2 ตัวล่าง"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <b-card-body>
                      <validation-observer ref="simpleRules1">
                        <b-row>
                          <b-col lg="4">
                            <b-form-group
                              label="เลขจ่ายตาม % 2 ตัวล่าง"
                              label-for="basicInput"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name=""
                                rules="required|min:2"
                              >
                                <b-form-input
                                  id="basicInput"
                                  v-model="NumLotD2"
                                  type="number"
                                  maxlength="2"
                                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="กรอกเลขจ่ายตาม % 2 ตัวล่าง"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col lg="4">
                            <b-form-group
                              label="อัตราการจ่ายเงิน"
                              label-for="basicInput"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name=""
                                rules="required"
                              >
                                <b-form-input
                                  id="basicInput"
                                  v-model="RateD2"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="กรอกอัตราการจ่ายเงิน"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col lg="4">
                            <div>
                              <b-form-group
                                label="."
                                label-for="basicInput"
                              >
                                <b-button
                                  variant="primary"
                                  @click="validPushD2"
                                >
                                  เพิ่มเลขจ่ายตาม % 2 ตัวล่าง
                                </b-button>
                              </b-form-group>
                            </div>
                          </b-col>
                        </b-row>
                      </validation-observer>

                      <div class="d-flex justify-content-start mb-1">
                        <b-button
                          variant="relief-warning"
                          size="sm"
                          @click="RateD2 = RateDefault.D2 / 2"
                        >
                          <feather-icon icon="Edit3Icon" />
                          50 เปอร์เซ็น
                        </b-button>
                      </div>

                      <div>
                        <h3>เลขจ่ายตาม % 2 ตัวล่าง</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageD2"
                      :fields="FieldsLimit"
                      :items="ItemsD2"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageD2 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsD2.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageD2"
                          :total-rows="ItemsD2.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseD2">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 2 ตัวล่าง"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:2"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotD2"
                                type="number"
                                maxlength="2"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 2 ตัวล่าง"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushD2"
                              >
                                เพิ่มเลขปิดรับ 2 ตัวล่าง
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 2 ตัวล่าง</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageD2"
                      :fields="FieldsCloseBet"
                      :items="CloseBetD2"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageD2 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetD2.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageD2"
                          :total-rows="CloseBetD2.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 't2')"
              title="2 ตัวโต๊ด(บน)"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesT2">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % 2 ตัวโต๊ด(บน)"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:2"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotT2"
                                type="number"
                                maxlength="2"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % 2 ตัวโต๊ด(บน)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateT2"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushT2"
                              >
                                เพิ่มเลขจ่ายตาม % 2 ตัวโต๊ด(บน)
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateT2 = RateDefault.T2 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>

                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % 2 ตัวโต๊ด(บน)</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageT2"
                      :fields="FieldsLimit"
                      :items="ItemsT2"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageT2 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsT2.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageT2"
                          :total-rows="ItemsT2.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseT2">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ 2 ตัวโต๊ด(บน)"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:2"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotT2"
                                type="number"
                                maxlength="2"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ 2 ตัวโต๊ด(บน)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushT2"
                              >
                                เพิ่มเลขปิดรับ 2 ตัวโต๊ด(บน)
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ 2 ตัวโต๊ด(บน)</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageT2"
                      :fields="FieldsCloseBet"
                      :items="CloseBetT2"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageT2 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetT2.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageT2"
                          :total-rows="CloseBetT2.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
              title="วิ่งบน"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesU1">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % วิ่งบน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:1"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotU1"
                                type="number"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % วิ่งบน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateU1"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushU1"
                              >
                                เพิ่มเลขจ่ายตาม % วิ่งบน
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateU1 = RateDefault.U1 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>

                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % วิ่งบน</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageU1"
                      :fields="FieldsLimit"
                      :items="ItemsU1"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageU1 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsU1.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageU1"
                          :total-rows="ItemsU1.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseU1">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ วิ่งบน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:1"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotU1"
                                type="number"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ วิ่งบน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushU1"
                              >
                                เพิ่มเลขปิดรับ วิ่งบน
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <b-card-body>
                      <div>
                        <h3>เลขเลขปิดรับ วิ่งบน</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageU1"
                      :fields="FieldsCloseBet"
                      :items="CloseBetU1"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageU1 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetU1.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageU1"
                          :total-rows="CloseBetU1.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
              title="วิ่งล่าง"
            >
              <hr>
              <b-row>
                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesD1">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขจ่ายตาม % วิ่งล่าง"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:1"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="NumLotD1"
                                type="number"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขจ่ายตาม % วิ่งล่าง"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4">
                          <b-form-group
                            label="อัตราการจ่ายเงิน"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="RateD1"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกอัตราการจ่ายเงิน"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="validPushD1"
                              >
                                เพิ่มเลขจ่ายตาม % วิ่งล่าง
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>

                    <div class="d-flex justify-content-start mb-1">
                      <b-button
                        variant="relief-warning"
                        size="sm"
                        @click="RateD1 = RateDefault.D1 / 2"
                      >
                        <feather-icon icon="Edit3Icon" />
                        50 เปอร์เซ็น
                      </b-button>
                    </div>

                    <b-card-body>
                      <div>
                        <h3>เลขจ่ายตาม % วิ่งล่าง</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="currentPageD1"
                      :fields="FieldsLimit"
                      :items="ItemsD1"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (currentPageD1 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="ItemsD1.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="currentPageD1"
                          :total-rows="ItemsD1.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>

                <b-col lg="6">
                  <b-card no-body>
                    <validation-observer ref="RulesCloseD1">
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label="เลขปิดรับ วิ่งล่าง"
                            label-for="basicInput"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name=""
                              rules="required|min:1"
                            >
                              <b-form-input
                                id="basicInput"
                                v-model="CloseNumLotD1"
                                type="number"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :state="errors.length > 0 ? false : null"
                                placeholder="กรอกเลขปิดรับ วิ่งล่าง"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col lg="4">
                          <div>
                            <b-form-group
                              label="."
                              label-for="basicInput"
                            >
                              <b-button
                                variant="primary"
                                @click="ClosePushD1"
                              >
                                เพิ่มเลขปิดรับ วิ่งล่าง
                              </b-button>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <b-card-body>
                      <div>
                        <h3>เลขปิดรับ วิ่งล่าง</h3>
                      </div>
                    </b-card-body>
                    <b-table
                      responsive="sm"
                      class="position-relative items-center"
                      per-page="5"
                      :current-page="ClosePageD1"
                      :fields="FieldsCloseBet"
                      :items="CloseBetD1"
                    >
                      <template #cell(index)="data">
                        {{ 5 * (ClosePageD1 - 1) + (data.index + 1) }}
                      </template>
                      <template #cell(delete)="data">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="CloseBetD1.splice(data.index, 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </template>
                    </b-table>

                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                      <!-- pagination -->
                      <div>
                        <b-pagination
                          v-model="ClosePageD1"
                          :total-rows="CloseBetD1.length"
                          per-page="5"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          lass="mb-1 ml-1"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </b-card-body>

                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

    </b-row>
    <!-- Modal -->
    <b-modal
      v-model="MdLimit"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      size="lg"
      :title="MdLimitName"
    >
      <b-table
        responsive="sm"
        class="position-relative items-center"
        per-page="5"
        :current-page="currentPageLimitItems"
        :fields="FieldsMdLimit"
        :items="MdLimitItems"
      >
        <template #cell(index)="data">
          {{ 5 * (currentPageLimitItems - 1) + (data.index + 1) }}
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPageLimitItems"
            :total-rows="MdLimitItems.length"
            per-page="5"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            lass="mb-1 ml-1"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BPagination,
  BTable,
  // BInputGroupPrepend,
  // BFormSelect,
  // BFormDatepicker,
  // BFormTimepicker,
  BCardBody,
  BAvatar,
  BBadge,
  BOverlay,
  BIconController,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, between, length, min,
} from '@validations'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    vSelect,
    // BInputGroupPrepend,
    BCardBody,
    // BFormSelect,
    DatePicker,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BTable,
    // BFormDatepicker,
    // BFormTimepicker,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BOverlay,
    BIconController,
    BTabs,
    BTab,
  },
  data() {
    return {
      time1: null,
      time2: null,
      min,
      required,
      integer,
      between,
      length,
      activeBtn: '',
      ShowRound: false,
      U3: 3,
      show: true,
      EditID: null,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      totalRows: 1,
      currentPage: 1,
      currentPageU3: 1,
      currentPageD3: 1,
      currentPageB3: 1,
      currentPageT3: 1,
      currentPageU2: 1,
      currentPageD2: 1,
      currentPageT2: 1,
      currentPageU1: 1,
      currentPageD1: 1,

      ClosePageU3: 1,
      ClosePageD3: 1,
      ClosePageB3: 1,
      ClosePageT3: 1,
      ClosePageU2: 1,
      ClosePageD2: 1,
      ClosePageT2: 1,
      ClosePageU1: 1,
      ClosePageD1: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 5,
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'StatusResult', label: 'สถานะออกรางวัล' },
        {
          key: 'LottoHead',
          label: 'หวย',
          formatter: value => (this.NameLottoHead(value) || '-'),
        },
        {
          key: 'LottoSubHead',
          label: 'ประเภท',
        },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'StopBetTime', label: 'เวลาปิดรอบแทง' },
        // { key: 'LimitU3', label: 'เลขจ่ายตาม % 3 ตัวบน' },
        // { key: 'LimitD3', label: 'เลขจ่ายตาม % 3 ตัวล่าง' },
        // { key: 'LimitT3', label: 'เลขจ่ายตาม % 3 ตัวโต๊ด' },
        // { key: 'LimitU2', label: 'เลขจ่ายตาม % 2 ตัวบน' },
        // { key: 'LimitD2', label: 'เลขจ่ายตาม % 2 ตัวล่าง' },
        // { key: 'LimitT2', label: 'เลขจ่ายตาม % 2 ตัวโต๊ด(บน)' },
        // { key: 'LimitU1', label: 'เลขจ่ายตาม % วิ่งบน' },
        // { key: 'LimitD1', label: 'เลขจ่ายตาม % วิ่งล่าง' },
        { key: 'action', label: 'แก้ไข' },
      ],
      /* eslint-disable global-require */
      items: [],
      FieldsLimit: [
        { key: 'index', label: 'เลขที่' },
        { key: 'NumLot', label: 'เลขจ่ายตาม %' },
        { key: 'Rate', label: 'อัตราจ่าย' },
        { key: 'delete', label: 'ลบ' },
      ],
      FieldsCloseBet: [
        { key: 'index', label: 'เลขที่' },
        { key: 'NumLot', label: 'เลขปิดรับ' },
        { key: 'delete', label: 'ลบ' },
      ],
      FieldsMdLimit: [
        { key: 'index', label: 'เลขที่' },
        { key: 'NumLot', label: 'เลขจ่ายตาม %' },
        { key: 'Rate', label: 'อัตราจ่าย' },
      ],
      ItemsU3: [],
      ItemsD3: [],
      ItemsB3: [],
      ItemsT3: [],
      ItemsU2: [],
      ItemsD2: [],
      ItemsT2: [],
      ItemsU1: [],
      ItemsD1: [],

      CloseBetU3: [],
      CloseBetD3: [],
      CloseBetB3: [],
      CloseBetT3: [],
      CloseBetU2: [],
      CloseBetD2: [],
      CloseBetT2: [],
      CloseBetU1: [],
      CloseBetD1: [],
      RateDefault: {
        U3: 0,
        D3: 0,
        B3: 0,
        T3: 0,
        U2: 0,
        D2: 0,
        T2: 0,
        U1: 0,
        D1: 0,
      },
      CloseDate: null,
      StopBetTime: null,
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,

      NumLotU3: null,
      RateU3: null,
      NumLotD3: null,
      RateD3: null,
      NumLotB3: null,
      RateB3: null,
      NumLotT3: null,
      RateT3: null,
      NumLotU2: null,
      RateU2: null,
      NumLotD2: null,
      RateD2: null,
      NumLotT2: null,
      RateT2: null,
      NumLotU1: null,
      RateU1: null,
      NumLotD1: null,
      RateD1: null,

      CloseNumLotU3: null,
      CloseNumLotD3: null,
      CloseNumLotB3: null,
      CloseNumLotT3: null,
      CloseNumLotU2: null,
      CloseNumLotD2: null,
      CloseNumLotT2: null,
      CloseNumLotU1: null,
      CloseNumLotD1: null,

      MdLimit: false,
      MdLimitName: '',
      MdLimitItems: [],
      currentPageLimitItems: 1,
      StatusResult: true,
      StatusRate: true,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    this.LottoList = await this.GetSublist()
    // console.log(this.LottoList)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getRound()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    getRate(LottoHead, LottoSubHead) {
      this.ShowRound = true
      const params = {
        LottoHead,
        LottoSubHead,
      }
      this.$http
        .get('rate/show', { params })
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.StatusRate = true
            this.RateDefault.U3 = ResData.mes.U3
            this.RateDefault.D3 = ResData.mes.D3
            this.RateDefault.B3 = ResData.mes.B3
            this.RateDefault.T3 = ResData.mes.T3
            this.RateDefault.U2 = ResData.mes.U2
            this.RateDefault.D2 = ResData.mes.D2
            this.RateDefault.T2 = ResData.mes.T2
            this.RateDefault.U1 = ResData.mes.U1
            this.RateDefault.D1 = ResData.mes.D1
          } else {
            this.StatusRate = false
            this.RateDefault = {
              U3: 0,
              D3: 0,
              B3: 0,
              T3: 0,
              U2: 0,
              D2: 0,
              T2: 0,
              U1: 0,
              D1: 0,
            }
          }
          this.ShowRound = false
        })
        .catch(error => {
          console.log(error)
          this.ShowRound = false
        })
    },
    getRound() {
      this.ShowRound = true
      this.$http
        .get('round/showlastall')
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.onFiltered(ResData.mes)
          }
          this.ShowRound = false
        })
        .catch(error => {
          console.log(error)
          this.ShowRound = false
        })
    },
    validatRound() {
      this.$refs.simpleRound.validate().then(success => {
        if (success) {
          if (this.EditID) {
            this.submitEdit()
          } else {
            this.submit()
          }
        }
      })
    },
    submit() {
      this.show = true
      const CloseDateFix = `${this.CloseDate} ${this.StopBetTime}`
      const formData = {
        LottoHead: this.LottoHead,
        LottoSubHead: this.LottoSubHead,
        CloseDate: CloseDateFix,
        CloseDateName: this.CalNameDate(CloseDateFix),
        StopBetTime: this.StopBetTime,
        LimitU3: this.ItemsU3,
        LimitD3: this.ItemsD3,
        LimitB3: this.ItemsB3,
        LimitT3: this.ItemsT3,
        LimitU2: this.ItemsU2,
        LimitD2: this.ItemsD2,
        LimitT2: this.ItemsT2,
        LimitU1: this.ItemsU1,
        LimitD1: this.ItemsD1,
        CloseBetU3: this.CloseBetU3,
        CloseBetD3: this.CloseBetD3,
        CloseBetB3: this.CloseBetB3,
        CloseBetT3: this.CloseBetT3,
        CloseBetU2: this.CloseBetU2,
        CloseBetD2: this.CloseBetD2,
        CloseBetT2: this.CloseBetT2,
        CloseBetU1: this.CloseBetU1,
        CloseBetD1: this.CloseBetD1,
        StatusResult: false,
      }
      this.$http
        .post('round/store', formData)
        .then(() => {
          this.show = false
          this.LottoHead = null
          this.LottoSubHead = null
          this.LottoSubList = null
          this.ClearItems()
          this.getRound()
          this.Success('บันทึกเปิด-ปิดรอบแทง สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    submitEdit() {
      this.show = true
      const CloseDateFix = `${this.CloseDate} ${this.StopBetTime}`
      const formData = {
        LottoHead: this.LottoHead,
        LottoSubHead: this.LottoSubHead,
        CloseDate: CloseDateFix,
        CloseDateName: this.CalNameDate(CloseDateFix),
        StopBetTime: this.StopBetTime,
        LimitU3: this.ItemsU3,
        LimitD3: this.ItemsD3,
        LimitB3: this.ItemsB3,
        LimitT3: this.ItemsT3,
        LimitU2: this.ItemsU2,
        LimitD2: this.ItemsD2,
        LimitT2: this.ItemsT2,
        LimitU1: this.ItemsU1,
        LimitD1: this.ItemsD1,

        CloseBetU3: this.CloseBetU3,
        CloseBetD3: this.CloseBetD3,
        CloseBetB3: this.CloseBetB3,
        CloseBetT3: this.CloseBetT3,
        CloseBetU2: this.CloseBetU2,
        CloseBetD2: this.CloseBetD2,
        CloseBetT2: this.CloseBetT2,
        CloseBetU1: this.CloseBetU1,
        CloseBetD1: this.CloseBetD1,
        StatusResult: false,
      }
      this.$http
        .post(`round/update/${this.EditID}`, formData)
        .then(() => {
          this.show = false
          this.LottoHead = null
          this.LottoSubHead = null
          this.LottoSubList = null
          this.ClearItems()
          this.getRound()
          this.Success('บันทึกเปิด-ปิดรอบแทง สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    CheckResult() {
      if (this.LottoHead && !this.LottoSubList) {
        this.getRate(this.LottoHead, this.LottoSubHead)
        const Index = this.items.findIndex(({ LottoHead }) => LottoHead === this.LottoHead)
        if (Index >= 0) {
          this.StatusResult = this.items[Index].StatusResult
        } else {
          this.StatusResult = true
        }
      } else if (this.LottoSubHead) {
        this.getRate(this.LottoHead, this.LottoSubHead)
        const Index = this.items.findIndex(({ LottoSubHead }) => LottoSubHead === this.LottoSubHead)
        if (Index >= 0) {
          this.StatusResult = this.items[Index].StatusResult
        } else {
          this.StatusResult = true
        }
      }
    },
    EditFu(Data) {
      this.EditID = Data.id
      this.FindSublist(Data.LottoHead)
      this.LottoHead = Data.LottoHead
      this.LottoSubHead = Data.LottoSubHead
      this.getRate(this.LottoHead, this.LottoSubHead)
      this.ItemsU3 = Data.LimitU3 ? Data.LimitU3 : []
      this.ItemsD3 = Data.LimitD3 ? Data.LimitD3 : []
      this.ItemsB3 = Data.LimitB3 ? Data.LimitB3 : []
      this.ItemsT3 = Data.LimitT3 ? Data.LimitT3 : []
      this.ItemsU2 = Data.LimitU2 ? Data.LimitU2 : []
      this.ItemsD2 = Data.LimitD2 ? Data.LimitD2 : []
      this.ItemsT2 = Data.LimitT2 ? Data.LimitT2 : []
      this.ItemsU1 = Data.LimitU1 ? Data.LimitU1 : []
      this.ItemsD1 = Data.LimitD1 ? Data.LimitD1 : []

      this.CloseBetU3 = Data.CloseBetU3 ? Data.CloseBetU3 : []
      this.CloseBetD3 = Data.CloseBetD3 ? Data.CloseBetD3 : []
      this.CloseBetB3 = Data.CloseBetB3 ? Data.CloseBetB3 : []
      this.CloseBetT3 = Data.CloseBetT3 ? Data.CloseBetT3 : []
      this.CloseBetU2 = Data.CloseBetU2 ? Data.CloseBetU2 : []
      this.CloseBetD2 = Data.CloseBetD2 ? Data.CloseBetD2 : []
      this.CloseBetT2 = Data.CloseBetT2 ? Data.CloseBetT2 : []
      this.CloseBetU1 = Data.CloseBetU1 ? Data.CloseBetU1 : []
      this.CloseBetD1 = Data.CloseBetD1 ? Data.CloseBetD1 : []
      this.CloseDate = momenttz(Data.CloseDate).tz('Asia/Bangkok').format('YYYY-MM-DD')
      this.StopBetTime = Data.StopBetTime
    },
    validPushU3() {
      this.$refs.simpleRules3.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.PushLimit(this.ItemsU3, this.NumLotU3, this.RateU3)
        }
      })
    },
    validPushD3() {
      this.$refs.RulesD3.validate().then(success => {
        if (success) {
          this.PushLimit(this.ItemsD3, this.NumLotD3, this.RateD3)
        }
      })
    },
    validPushB3() {
      this.$refs.RulesB3.validate().then(success => {
        if (success) {
          this.PushLimit(this.ItemsB3, this.NumLotB3, this.RateB3)
        }
      })
    },
    validPushT3() {
      this.$refs.RulesT3.validate().then(success => {
        if (success) {
          this.PushSortLimit(this.ItemsT3, this.NumLotT3, this.RateT3)
        }
      })
    },
    validPushU2() {
      this.$refs.simpleRules2.validate().then(success => {
        if (success) {
          this.PushLimit(this.ItemsU2, this.NumLotU2, this.RateU2)
        }
      })
    },
    validPushD2() {
      this.$refs.simpleRules1.validate().then(success => {
        if (success) {
          this.PushLimit(this.ItemsD2, this.NumLotD2, this.RateD2)
        }
      })
    },
    validPushT2() {
      this.$refs.RulesT2.validate().then(success => {
        if (success) {
          this.PushSortLimit(this.ItemsT2, this.NumLotT2, this.RateT2)
        }
      })
    },
    validPushU1() {
      this.$refs.RulesU1.validate().then(success => {
        if (success) {
          this.PushLimit(this.ItemsU1, this.NumLotU1, this.RateU1)
        }
      })
    },
    validPushD1() {
      this.$refs.RulesD1.validate().then(success => {
        if (success) {
          this.PushLimit(this.ItemsD1, this.NumLotD1, this.RateD1)
        }
      })
    },
    ClosePushU3() {
      this.$refs.RulesCloseU3.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.PushClose(this.CloseBetU3, this.CloseNumLotU3)
        }
      })
    },
    ClosePushD3() {
      this.$refs.RulesCloseD3.validate().then(success => {
        if (success) {
          this.PushClose(this.CloseBetD3, this.CloseNumLotD3)
        }
      })
    },
    ClosePushB3() {
      this.$refs.RulesCloseB3.validate().then(success => {
        if (success) {
          this.PushClose(this.CloseBetB3, this.CloseNumLotB3)
        }
      })
    },
    ClosePushT3() {
      this.$refs.RulesCloseT3.validate().then(success => {
        if (success) {
          this.PushSortClose(this.CloseBetT3, this.CloseNumLotT3)
        }
      })
    },
    ClosePushU2() {
      this.$refs.RulesCloseU2.validate().then(success => {
        if (success) {
          this.PushClose(this.CloseBetU2, this.CloseNumLotU2)
        }
      })
    },
    ClosePushD2() {
      this.$refs.RulesCloseD2.validate().then(success => {
        if (success) {
          this.PushClose(this.CloseBetD2, this.CloseNumLotD2)
        }
      })
    },
    ClosePushT2() {
      this.$refs.RulesCloseT2.validate().then(success => {
        if (success) {
          this.PushSortClose(this.CloseBetT2, this.CloseNumLotT2)
        }
      })
    },
    ClosePushU1() {
      this.$refs.RulesCloseU1.validate().then(success => {
        if (success) {
          this.PushClose(this.CloseBetU1, this.CloseNumLotU1)
        }
      })
    },
    ClosePushD1() {
      this.$refs.RulesCloseD1.validate().then(success => {
        if (success) {
          this.PushClose(this.CloseBetD1, this.CloseNumLotD1)
        }
      })
    },
    ModalLimit(Title, items) {
      this.MdLimit = !this.MdLimit
      this.MdLimitName = Title
      this.MdLimitItems = items
    },
    NameLottoHead(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      if (Index >= 0) {
        return this.LottoList[Index].text
      }
      return '-'
    },
    NameLottoSubHead(Head, Sub) {
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
        if (IndexSub >= 0) {
          return this.LottoList[IndexHead].SubList[IndexSub].text
        }
      }
      return '-'
    },
    PushSortLimit(Arr, Numlot, Rate) {
      if (Numlot && Rate) {
        const NumArr = Numlot.split('')
        NumArr.sort()
        const NumSort = NumArr.join('')
        const CheckDup = Arr.findIndex(obj => obj.NumSort === NumSort)
        if (CheckDup >= 0) {
          this.SwalError(`เลข ${Numlot} ซ้ำ`)
        } else {
          Arr.push({ NumLot: Numlot, Rate, NumSort })
          this.ClearValue()
        }
      }
    },
    PushLimit(Arr, Numlot, Rate) {
      if (Numlot && Rate) {
        const CheckDup = Arr.findIndex(obj => obj.NumLot === Numlot)
        if (CheckDup >= 0) {
          this.SwalError(`เลข ${Numlot} ซ้ำ`)
        } else {
          Arr.push({ NumLot: Numlot, Rate })
          this.ClearValue()
        }
      }
    },
    PushSortClose(Arr, Numlot) {
      if (Numlot) {
        const NumArr = Numlot.split('')
        NumArr.sort()
        const NumSort = NumArr.join('')
        const CheckDup = Arr.findIndex(obj => obj.NumSort === NumSort)
        if (CheckDup >= 0) {
          this.SwalError(`เลข ${Numlot} ซ้ำ`)
        } else {
          Arr.push({ NumLot: Numlot, NumSort })
          this.ClearValue()
        }
      }
    },
    PushClose(Arr, Numlot) {
      if (Numlot) {
        const CheckDup = Arr.findIndex(obj => obj.NumLot === Numlot)
        if (CheckDup >= 0) {
          this.SwalError(`เลข ${Numlot} ซ้ำ`)
        } else {
          Arr.push({ NumLot: Numlot })
          this.ClearValue()
        }
      }
    },
    ClearValue() {
      this.NumLotU3 = null
      this.RateU3 = null
      this.NumLotD3 = null
      this.RateD3 = null
      this.NumLotB3 = null
      this.RateB3 = null
      this.NumLotT3 = null
      this.RateT3 = null
      this.NumLotU2 = null
      this.RateU2 = null
      this.NumLotD2 = null
      this.RateD2 = null
      this.NumLotT2 = null
      this.RateT2 = null
      this.NumLotU1 = null
      this.RateU1 = null
      this.NumLotD1 = null
      this.RateD1 = null

      this.CloseNumLotU3 = null
      this.CloseNumLotD3 = null
      this.CloseNumLotB3 = null
      this.CloseNumLotT3 = null
      this.CloseNumLotU2 = null
      this.CloseNumLotD2 = null
      this.CloseNumLotT2 = null
      this.CloseNumLotU1 = null
      this.CloseNumLotD1 = null
    },
    FindSublist(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      if (Index >= 0) {
        this.StatusResult = true
      }
      this.LottoSubList = this.LottoList[Index].SubList
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    ClearItems() {
      this.EditID = null
      // this.LottoSubList = null
      this.CloseDate = null
      this.StopBetTime = null
      this.ItemsU3 = []
      this.ItemsD3 = []
      this.ItemsB3 = []
      this.ItemsT3 = []
      this.ItemsU2 = []
      this.ItemsD2 = []
      this.ItemsT2 = []
      this.ItemsU1 = []
      this.ItemsD1 = []

      this.CloseBetU3 = []
      this.CloseBetD3 = []
      this.CloseBetB3 = []
      this.CloseBetT3 = []
      this.CloseBetU2 = []
      this.CloseBetD2 = []
      this.CloseBetT2 = []
      this.CloseBetU1 = []
      this.CloseBetD1 = []
    },
    CalNameDate(Val) {
      const MonthTH = [
        '',
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ]
      const Year = Number(momenttz(Val).tz('Asia/Bangkok').format('YYYY')) + 543
      const Month = momenttz(Val).tz('Asia/Bangkok').format('M')
      const Day = momenttz(Val).tz('Asia/Bangkok').format('DD')

      return `${Day} ${MonthTH[Month]} ${Year}`
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn-show {

  background-color: #f5f5f5;
  border: 1px solid #cb0000;
  color: #cb0000;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* .active{
  background: rgb(58,8,8);
background: linear-gradient(158deg, rgba(58,8,8,1) 0%, rgba(203,0,0,1) 48%, rgba(118,5,5,1) 96%);
  color: #fff;
} */
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
